import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'publishLabel',
    'publishLater',
    'publishLaterCheckbox',
    'publishLaterLabel',
    'publishDate',
    'publishTime',
    'publishTimezone'
  ]

  togglePublishOptions(e) {
    if (e.target.value === 'true') {
      this.publishLaterTarget.classList.remove("d-none")
    } else {
      this.publishLaterTarget.classList.add("d-none")
    }
  }

  togglePublishLater(e) {
    if (e.target.value === 'later') {
      this.publishDateTarget.classList.remove("d-none")
      this.publishTimeTarget.classList.remove("d-none")
      this.publishTimezoneTarget.classList.remove("d-none")
    } else if (e.target.value === 'now') {
      this.publishDateTarget.classList.add("d-none")
      this.publishTimeTarget.classList.add("d-none")
      this.publishTimezoneTarget.classList.add("d-none")
    } else if (e.target.value === 'before') {
      this.publishDateTarget.classList.remove("d-none")
      this.publishTimeTarget.classList.add("d-none")
      this.publishTimezoneTarget.classList.add("d-none")
    }
  }
}
